import React, { useState, useEffect } from "react";
import InitiativeCard from "./InitiativeCard";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const InitiativeList = () => {
  const [initiatives, setInitiatives] = useState([]); // Initialize as an empty array
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URLVAR}/api/initiatives`)
      .then((response) => setInitiatives(response.data))
      .catch((err) => console.error(err));
  }, []);

  const handleShowMore = (initiative) => {
    navigate(`/initiative/${initiative.slug}`, { state: initiative });
  };

  return (
    <>
      <div className="d-flex justify-content-start align-items-center text-white heading-img">
        <div className="col-md-8">
          <h1 className="text-center">Our Initiative</h1>
          <h5 className="text-center px-2">
            Projects our team evaluated, sponsored, and supported.
            <br /> We welcome inspiring ideas by teams.
          </h5>
        </div>
      </div>
      <div className=" mt-5 container">
        <div className="row w-100 align-content-md-stretch">
        {Array.isArray(initiatives) && initiatives.length > 0 ? (
            initiatives.map((initiative, index) => (
             
                <InitiativeCard
                  title={initiative.title}
                  description={initiative.description}
                  location={initiative.location}
                  image={initiative.image}
                  slug={initiative.slug}
                  // reverse={index % 2 !== 0} // Add 'reverse' prop for alternating layout
                  onShowMore={() => handleShowMore(initiative)}
                />
             
            ))
          ) : (
            <p className="text-center">No initiatives found.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default InitiativeList;
