import React, { useEffect, useState } from "react";
import axios from "axios";

const WorkshopList = () => {
  const [workshops, setWorkshops] = useState([]);

  useEffect(() => {
    const fetchWorkshops = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_URLVAR}/api/workshops`);
        setWorkshops(response.data.workshops);
      } catch (error) {
        console.error("Error fetching workshops:", error);
      }
    };
    fetchWorkshops();
  }, []);

  return (
    <div className="container mt-5">
      {/* <h2>Workshops</h2> */}
      <h2 className="about-heading my-5 text-center">Workshops</h2>
      <div className="">
        {workshops&&workshops.map((workshop) => (
          <div className=" mb-4" key={workshop._id}>
            <div className="card h-100 shadow-lg">
              <div className="row g-0 p-4">
                <div className="col-md-6">
                  <img src={workshop.banner} className="img-fluid rounded-start" alt={workshop.name} />
                </div>
                <div className="col-md-6">
                  <div className="card-body">
                    <h5 className="card-title">{workshop.name}</h5>
                    <p className="card-text">Trainer: {workshop.trainer}</p>
                    <p className="card-text">Location: {workshop.location}</p>
                    <button
                      className="btn btn-custom-primary"
                      onClick={() => window.location.href = `/workshops/${workshop._id}`}
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorkshopList;
