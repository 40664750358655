import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./Timeline.css"; // Include your CSS from the original

const timelineData = [
  {
    year: "13 Dec 2024",
    title: "Why do we use it?",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout...",
    images: [
      "https://storage.googleapis.com/leadforearth_website/initiatives/1732557526102_2.png",
      "https://storage.googleapis.com/leadforearth_website/initiatives/1732557526102_2.png",
      "https://storage.googleapis.com/leadforearth_website/initiatives/1732557526102_2.png",
    ],
    alignment: "odd",
  },
  {
    year: "2005",
    title: "Why do we use it?",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout...",
    images: [
      "https://storage.googleapis.com/leadforearth_website/initiatives/1732557526102_2.png",
      "https://storage.googleapis.com/leadforearth_website/initiatives/1732557526102_2.png",
      "https://storage.googleapis.com/leadforearth_website/initiatives/1732557526102_2.png",
    ],
    alignment: "even",
  },
];

const Timeline = () => {
  return (
    <section id="site-timeline">
      <div className="site-timeline-content">
        {timelineData.map((item, index) => (
          <div
            key={index}
            className={`timeline-article ${item.alignment}`}
          >
            <div className="site-center-line"></div>
            {item.alignment === "odd" ? (
              <>
                <Carousel images={item.images}  index1={"sec"+index} alignment={item.alignment}/>
                <ContentRight item={item} />
              </>
            ) : (
              <>
                <ContentLeft item={item} />
                <Carousel images={item.images} index1={"sec"+index} />
              </>
            )}
            <div className="meta-dot"></div>
          </div>
        ))}
      </div>
    </section>
  );
};

const Carousel = ({ images,index1,alignment }) => {
  return (
    <div className={alignment === "odd"? "content-left-container":"content-right-container"}>
      <div id={index1} className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          {images.map((image, index) => (
            <div
              key={index}
              className={`carousel-item ${index === 0 ? "active" : ""}`}
            >
              <img src={image} className="d-block w-100" alt="carousel-item" />
            </div>
          ))}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target={"#"+index1}
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target={"#"+index1}
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

const ContentLeft = ({ item }) => (
  <div className="content-left-container">
    <div className="year__">
      <div className="line"></div>
      <h3 className="timeline-year">{item.year}</h3>
    </div>
    <div className="content-right">
      <h3 className="timeline-title">{item.title}</h3>
      <p>{item.description}</p>
    </div>
  </div>
);

const ContentRight = ({ item }) => (
  <div className="content-right-container">
    <div className="year__">
      <div className="line"></div>
      <h3 className="timeline-year">{item.year}</h3>
    </div>
    <div className="content-right">
      <h3 className="timeline-title">{item.title}</h3>
      <p>{item.description}</p>
    </div>
  </div>
);

export default Timeline;
