import React, { useEffect, useRef, useState } from "react";
import ProfileCard from "./ProfileCard";
import Slider from "react-slick";
import "./Home.css";
import ProfileCardSmall from "./ProfileCardSmall";
import nextArrowImg from "../../img/rightarrow.png";
import prevArrowImg from "../../img/leftarrow.png";
import logo from "../../img/logo.webp"; // Import logo image
import ConnectWithUsForm from "../ConnectWithUs/ConnectWithUsForm.js";
import { Link, useNavigate } from "react-router-dom";
import InstagramFeeds from "../Profilepage/InstagramFeeds.js";
import BlogList from "../Blog/BlogList.js";
import { Helmet } from "react-helmet";
import EventCarousel from "../Eventforlead/EventCarousel.js";
import Eventbanner from "../../img/eventbanner.webp";
import NewsList from "../News/NewsList.js";
import InitiativeCard from "../Initiatives/InitiativeCard.js";
import axios from "axios";
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const toRadians = (degrees) => degrees * (Math.PI / 180);

  const R = 6371; // Radius of the Earth in kilometers
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon1 - lon2);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers
  // console.log(distance)
  return distance * 0.621371; // Convert to miles
};

const generateSlug = (title) => {
  if (title === undefined) return "";
  return `${title.toLowerCase().replace(/ /g, "-")}`;
};

function Home({ location }) {
  const [leaders, setLeaders] = useState([]);
  const [filteredLeaders, setFilteredLeaders] = useState([]);
  // const [loading, setLoading] = useState(true); // Add loading state
  // console.log(loading);
  const [error, setError] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0); // Track the active index
  const carouselRef = useRef(null);
  const [initiatives, setInitiatives] = useState([]); // Initialize as an empty array
  const navigate = useNavigate();
  const [visibleCount, setVisibleCount] = useState(3); // Initial count of initiatives to display

  const handleShowMoreinitiative = () => {
    setVisibleCount((prevCount) => prevCount + 3); // Increase the count by 2 on each click
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URLVAR}/api/initiatives`)
      .then((response) => setInitiatives(response.data))
      .catch((err) => console.error(err));
  }, []);

  const handleShowMore = (initiative) => {
    navigate(`/initiative/${initiative.slug}`, { state: initiative });
  };
  const PrevArrow = ({ onClick }) => {
    return (
      <div className="slick-prev" onClick={onClick}>
        <img src={prevArrowImg} style={{ height: "30px" }} alt="Previous" />
      </div>
    );
  };

  const eventData = [
    {
      title: "Leadership and Entrepreneurship Workshop",
      duration: "40 hours",
      date: "Dec 1 to Dec 31",
      location: "San Ramon, CA",
      mode: "Hybrid",
      ageGroup: "12-18 years",
      trainer: "Chirag Kathrani",
      guestMembers:
        "C' Suite executives of Silicon Valley (Names will be disclosed Later)",
      prescreening: "Prescreening required as the team needs to be grouped.",
      keyHighlights: [
        "Complete Practical oriented.",
        "Students will be grouped into no more than 4 students based on skills.",
        "They will work on one use case and deliver an MVP.",
        "Learn best scope utilization of AI Techniques.",
      ],
      endGoal:
        "End Goal is to bring an entrepreneurial mindset in the students which will help them be future leaders.",
      image:
        "https://storage.googleapis.com/leadforearth_website/initiatives/1732557526102_2.png", // Replace with actual image
    },
  ];

  const NextArrow = ({ onClick }) => {
    return (
      <div className="slick-next" onClick={onClick}>
        <img src={nextArrowImg} style={{ height: "30px" }} alt="Next" />
      </div>
    );
  };
  const leaderssettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,

    responsive: [
      {
        breakpoint: 1300, // For MacBook 13-inch
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024, // For iPad
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768, // For small tablets
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600, // For mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    // setLoading(true)
    const fetchLeaders = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URLVAR}/api/leaders/home`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setLeaders(data);
        // setLoading(false); // Stop loading when data is fetched
      } catch (error) {
        setError(error.message);
        // setLoading(false); // Stop loading on error
      }
    };
    if (leaders.length === 0) {
      fetchLeaders();
    }
  }, []);

  useEffect(() => {
    const filterLeader = () => {
      if (leaders.length > 0) {
        let sortedLeaders;

        if (location && location.latitude && location.longitude) {
          const { latitude, longitude } = location;
          if (!isNaN(parseFloat(latitude)) && !isNaN(parseFloat(longitude))) {
            sortedLeaders = leaders
              .map((leader) => {
                const leaderLat = parseFloat(leader.lat);
                const leaderLng = parseFloat(leader.lng);

                if (isNaN(leaderLat) || isNaN(leaderLng)) {
                  console.error(
                    "Invalid leader coordinates:",
                    leader.lat,
                    leader.lng
                  );
                  return null;
                }

                const distance = calculateDistance(
                  latitude,
                  longitude,
                  leaderLat,
                  leaderLng
                );
                return { ...leader, distance };
              })
              .filter((leader) => leader !== null)
              .sort((a, b) => {
                if (a.name.startsWith("Chirag")) return -1;
                if (b.name.startsWith("Chirag")) return 1;
                return a.distance - b.distance; // Sort by distance after leaders with "Chirag"
              });
          } else {
            sortedLeaders = leaders.sort((a, b) => {
              if (a.name.startsWith("Chirag")) return -1;
              if (b.name.startsWith("Chirag")) return 1;
              return a.name.localeCompare(b.name);
            });
          }
        } else {
          sortedLeaders = leaders.sort((a, b) => {
            if (a.name.startsWith("Chirag")) return -1;
            if (b.name.startsWith("Chirag")) return 1;
            return a.name.localeCompare(b.name);
          });
        }

        setFilteredLeaders(sortedLeaders);

        if (carouselRef.current) {
          const items = carouselRef.current.querySelectorAll(".carousel-item");
          items.forEach((item, index) => {
            item.classList.remove("active");
            if (index === 0) {
              item.classList.add("active");
            }
          });
        }
      }
    };

    filterLeader();
  }, [location, leaders]);

  // if (loading) {
  //   return (
  //     <div className="preloader-container">
  //       <img src={logo} alt="Loading..." />
  //     </div>
  //   );
  // }
  const handleSlideChange = (newIndex) => {
    setActiveIndex(newIndex);
  };
  return (
    <>
      <Helmet>
        <title>Lead4earth</title>
      </Helmet>
      <div className="top-header text-white ">
        <a
          href="https://www.facebook.com/share/pgJefT943e486gxW/?mibextid=LQQJ4d"
          className="social-media-btn mx-2"
        >
          <i className="bi bi-facebook"></i>
        </a>
        <a
          href="https://www.instagram.com/lead4earth/"
          className="social-media-btn mx-2"
        >
          <i className="bi bi-instagram"></i>
        </a>
        <a
          href="https://www.linkedin.com/company/lead-for-earth/"
          className="social-media-btn mx-2"
        >
          <i className="bi bi-linkedin"></i>
        </a>
        <a href="https://x.com/nisarg_f" className="social-media-btn mx-2">
          <i className="bi bi-twitter-x"></i>
        </a>
        <a
          href="https://www.youtube.com/@Lead4_Earth"
          className="social-media-btn mx-2"
        >
          <i className="bi bi-youtube"></i>
        </a>
      </div>
      <div className="herotitle">
        <div>
          Engaging <b>communities</b> for{" "}
        </div>
        <div>
          local <b>Sustainability</b> Movement.
        </div>
      </div>
      <div className="herodesc">
        <div>Your Local Environmental Leaders</div>
      </div>
      {/* <div className=" mt-5">
        
        <EventCarousel events={eventData} />
      </div> */}

      {/* <a href="https://lead4earth.org/rsvp/leadership-and-entrepreneurship-workshop" target="_blank">

        <img src={Eventbanner} alt="event banner" className="img-fluid"/>
        </a> */}

      {/* <div
        id="carouselExampleControls"
        className="carousel slide"
        // data-bs-ride="carousel"
        ref={carouselRef}
      >
        <div className="carousel-inner">
          {filteredLeaders.map(
            (profile, index) =>
              profile.preferred && (
                <div
                  className={`carousel-item ${index === 0 ? "active" : ""}`}
                  key={profile._id}
                  aria-hidden={index !== 0 ? "true" : "false"}
                >
                  <ProfileCard
                    id={profile._id}
                    name={profile.name}
                    title={profile.title}
                    location={profile.location}
                    priorities={profile.priorities}
                    image={profile.image}
                    distance={profile.distance}
                    slug={generateSlug(profile.title)}
                    tabIndex={index !== 0 ? "-1" : "0"}
                  />
                </div>
              )
          )}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div> */}

      <div className=" mt-5">
        <div
          id="initiativeCarousel"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            {initiatives.map((initiative, index) => (
              <div
                key={initiative.slug}
                className={`carousel-item ${index === 0 ? "active" : ""}`}
              >
                <a onClick={() => handleShowMore(initiative)} style={{cursor:"pointer"}}>
                  <img
                    src={initiative.image || "placeholder.jpg"}
                    className="d-block w-100 img-fluid"
                    alt={initiative.title}
                    style={{ maxHeight: "", objectFit: "contain" }}
                  />
                </a>
              </div>
            ))}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#initiativeCarousel"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#initiativeCarousel"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <NewsList />
      
      {/* blog */}
      <BlogList numToShow={3} />
      <div className="container d-flex justify-content-center">
        <Link to="/blog" className="btn btn-custom-primary my-3">
          Show all
        </Link>
      </div>
      {/* connect with us */}
      {/* <ConnectWithUsForm /> */}
      {/* <img src={mission}/> */}
      {/* our mission */}
      <div className="mission-section position-relative">
        <div className="mission-content">
          <h1>Our Mission</h1>
          <p>
            To encourage, endorse and empower environmental and sustainability
            leaders at down ballot races. Tsunami of environmental leaders
            across leadership spectrum can increase our opportunities to take
            actions.
          </p>
          <Link to="/about-us" className="cta-button">
            View More
          </Link>
        </div>
      </div>
     

      <h2 className="mb-3 text-center mt-5" style={{ color: "#0C7A31FF" }}>
        Initiative
      </h2>
      <div className="mt-5 container">
        <div className="row w-100 align-content-md-stretch">
          {Array.isArray(initiatives) && initiatives.length > 0 ? (
            initiatives.slice(0, visibleCount).map((initiative, index) => (
              <InitiativeCard
                key={initiative.id}
                title={initiative.title}
                description={initiative.description}
                location={initiative.location}
                image={initiative.image}
                slug={initiative.slug}
                reverse={index % 2 !== 0} // Alternating layout
                onShowMore={() => handleShowMore(initiative)}
              />
            ))
          ) : (
            <p className="text-center">No initiatives found.</p>
          )}
        </div>
        {visibleCount < initiatives.length && (
          <div className="text-center mt-4">
            <button
              className="btn btn-custom-primary"
              onClick={handleShowMoreinitiative}
            >
              Show More
            </button>
          </div>
        )}
      </div>


      {/* instafeed */}
      <h2 className="mb-3 text-center mt-5" style={{ color: "#0C7A31FF" }}>
        Feed
      </h2>
      <InstagramFeeds hashtag={"#Lead4Earth"} noofpost={3} />

      {/* meet our leader */}
      <div className="leader-carousel">
        <h2 className="mb-3">Meet Our Promoters</h2>
        <p className="text-secondary">
          Progressive candidates need support. Sharing experiences empowers us
          all. Unite for a brighter future.
        </p>
        {filteredLeaders.length === 1 ? (
          <div className="row justify-content-center">
            <div className="col-md-3">
              <ProfileCardSmall leader={filteredLeaders[0]} />
            </div>
          </div>
        ) : (
          <Slider {...leaderssettings}>
            {filteredLeaders.map(
              (leader, index) =>
                leader.preferred && (
                  <div
                    key={index}
                    aria-hidden={index !== 0 ? "true" : "false"}
                    tabIndex={index !== 0 ? "-1" : "0"} // Manage focus
                  >
                    <ProfileCardSmall
                      leader={leader}
                      slug={generateSlug(leader.title)}
                    />
                  </div>
                )
            )}
          </Slider>
        )}
      </div>
    </>
  );
}

export default Home;
