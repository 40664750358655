// src/Footer.js
import React from "react";
import "./Footer.css";
import Logo from "../../img/logo.webp";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <footer className="bg-custom-primary text-white p-4 mt-4">
      <div className="row ">
        <div className="col-md-3 text-center text-md-left mb-4 mb-md-0 mt-3">
          <img src={Logo} alt="Logo" className="img-fluid mb-2 logo-width" />{" "}
          <h3 className="fw-semibold">Lead For Earth </h3>
        </div>
        
        <div className="col-md-3 mt-5 text-center">
          <h5 className="text-uppercase">Resources</h5>
          <ul className="list-unstyled mt-3">
            <li>
            <a href="https://www.paypal.com/donate/?hosted_button_id=ZZFFL8XAX8EZW" >DONATE</a>
            </li>
            {/* <li>
              <a href="#" className="text-white">
                User guides
              </a>
            </li>
            <li>
              <a href="#" className="text-white">
                Community
              </a>
            </li>
            <li>
              <a href="#" className="text-white">
                Developers
              </a>
            </li> */}
            <li>
              <Link to="/faq" onClick={() => {
                  window.scrollTo(0, 0);
                }} className="text-white">
                FAQs
              </Link>
            </li>
            <li>
              <Link
                to="/PrivacyPolicy"
                className="text-white"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Privacy
              </Link>
            </li>
            <li>
              <Link
                to="/blog"
                className="text-white"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Blogs
              </Link>
            </li>
            <li>
              <Link
                to="/leader"
                className="text-white"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Leaders
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-md-3 mt-5 text-center">
          <h5 className="text-uppercase">Company</h5>
          <ul className="list-unstyled mt-3">
            <li>
              <Link
                to="/about-us"
                className="text-white"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                About us
              </Link>
            </li>

            <li>
              <a target='_blank' href="https://docs.google.com/forms/d/e/1FAIpQLSfYmeBFoYo8BkXogAW6-J76UEuTORgx-sS8RODeaEflhcxPMQ/viewform?usp=sf_link" className="text-white">
                Join With Us
                {/* <span className="badge badge-warning">We're hiring</span> */}
              </a>
            </li>
            <li>
              <a href="mailto: info@lead4earth.org" className="text-white">
                info@lead4earth.org
              </a>
            </li>
            <li>
              <a href="tel:+1(925) 690-1013" className="text-white">
                +1(925) 690-1013
              </a>
            </li>
          </ul>
        </div>
        <div className="col-md-3 mt-5 text-center">
          <h5 className="text-uppercase">Leader</h5>
          <ul className="list-unstyled">
            <li>
              <Link to="/addleader" className="text-white">
                Sign-Up
              </Link>
            </li>
            <li>
              <Link to="/login" className="text-white">
                Log-In
              </Link>
            </li>
            
          </ul>
        </div>
        {/* <div className="col-md-3 mt-5">
          <h5 className="text-uppercase">Subscribe to our newsletter</h5>
          <p>For product announcements and exclusive insights</p>
          <form className="form-inline">
            <div className="input-group">
              <input
                type="email"
                className="form-control"
                placeholder="Input your email"
                aria-label="Email"
              />
              <div className="input-group-append">
                <button className="btn btn-primary" type="submit">
                  Subscribe
                </button>
              </div>
            </div>
          </form>
        </div> */}
      </div>
      <div className="footer-bottom container w-100 mt-3 px-5">
        <div className="  d-flex justify-content-end align-items-center">
          <small>
            © 2024 Lead For Earth |
            {/* <a href="#" className="text-white">
              Privacy
            </a>
            <a href="#" className="text-white">
              Sitemap
            </a> */}
          </small>
        </div>
        <div className="d-flex">
          <a href="https://www.facebook.com/share/15NUVAhKnt/?mibextid=LQQJ4d" className="mx-2">
            <i className="bi bi-facebook"></i>
          </a>
          <a href="https://www.instagram.com/lead4earth/" className="mx-2">
            <i className="bi bi-instagram"></i>
          </a>
          <a href="https://x.com/nisarg_f" className="mx-2">
            <i className="bi bi-twitter-x"></i>
          </a>
          <a
            href="https://www.linkedin.com/company/lead-for-earth/"
            className="mx-2"
          >
            <i className="bi bi-linkedin"></i>
          </a>
          <a href="https://www.youtube.com/@Lead4_Earth" className="mx-2">
            <i className="bi bi-youtube"></i>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
