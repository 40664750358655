import React, { useState, useEffect } from 'react';
import axios from 'axios';

const InitiativeForm = () => {
  const [title, setTitle] = useState('');
  const [slug, setSlug] = useState('');
  const [description, setDescription] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [location, setLocation] = useState('');
  const [image, setImage] = useState(null);
  const [customContent, setCustomContent] = useState('');
  const [initiatives, setInitiatives] = useState([]);
  const [editingId, setEditingId] = useState(null);

  const fetchInitiatives = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URLVAR}/api/initiatives`);
      setInitiatives(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchInitiatives();
  }, []);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSlugChange = (e) => {
    const value = e.target.value;
    const formattedSlug = value.toLowerCase().replace(/ /g, '-');
    setSlug(formattedSlug);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('slug', slug);
    formData.append('description', description);
    formData.append('metaDescription', metaDescription);
    formData.append('location', location);
    formData.append('image', image);
    formData.append('customContent', customContent);

    try {
      if (editingId) {
        await axios.put(`${process.env.REACT_APP_URLVAR}/api/initiatives/${editingId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-auth-token': localStorage.getItem('Token'),
          },
        });
        alert('Initiative updated successfully');
      } else {
        await axios.post(`${process.env.REACT_APP_URLVAR}/api/initiatives`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-auth-token': localStorage.getItem('Token'),
          },
        });
        alert('Initiative added successfully');
      }
      resetForm();
      fetchInitiatives();
    } catch (error) {
      console.error(error);
      alert('Error saving initiative');
    }
  };

  const resetForm = () => {
    setTitle('');
    setSlug('');
    setDescription('');
    setMetaDescription('');
    setLocation('');
    setImage(null);
    setCustomContent('');
    setEditingId(null);
  };

  const handleEdit = (initiative) => {
    setTitle(initiative.title);
    setSlug(initiative.slug);
    setDescription(initiative.description);
    setMetaDescription(initiative.metaDescription);
    setLocation(initiative.projectLocation);
    setCustomContent(initiative.customContent);
    setEditingId(initiative._id);
  };

  return (
    <div className='container'>
      <form onSubmit={handleSubmit} className="mb-4 border rounded-4 p-4 shadow-lg">
        <div className="mb-3">
          <label htmlFor="title" className="form-label">Title</label>
          <input
            type="text"
            className="form-control"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="slug" className="form-label">Slug</label>
          <input
            type="text"
            className="form-control"
            id="slug"
            value={slug}
            onChange={handleSlugChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">Description</label>
          <textarea
            className="form-control"
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="metaDescription" className="form-label">Meta Description</label>
          <textarea
            className="form-control"
            id="metaDescription"
            value={metaDescription}
            onChange={(e) => setMetaDescription(e.target.value)}
          ></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="location" className="form-label">Location</label>
          <input
            type="text"
            className="form-control"
            id="location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="image" className="form-label">Image</label>
          <input
            type="file"
            className="form-control"
            id="image"
            onChange={handleImageChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="customContent" className="form-label">Custom HTML, CSS, and JS</label>
          <textarea
            className="form-control"
            id="customContent"
            value={customContent}
            onChange={(e) => setCustomContent(e.target.value)}
            rows="6"
          ></textarea>
        </div>
        <button type="submit" className="btn btn-custom-primary">
          {editingId ? 'Update Initiative' : 'Add Initiative'}
        </button>
      </form>

      <div className="initiative-list">
        <h3>Existing Initiatives</h3>
        <ul className="list-group">
          {initiatives.map((initiative) => (
            <li key={initiative._id} className="list-group-item">
              <h5>{initiative.title}</h5>
              <p>{initiative.metaDescription}</p>
              <button
                className="btn btn-sm btn-secondary"
                onClick={() => handleEdit(initiative)}
              >
                Edit
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default InitiativeForm;
