import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

const NewsDetail = () => {
  const { slug } = useParams();
  const [news, setNews] = useState(null);

  useEffect(() => {
    const fetchNews = async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_URLVAR}/api/news/${slug}`);
      setNews(data);
    };
    fetchNews();
  }, [slug]);

  if (!news) return <div>Loading...</div>;

  return (
    <div className="container mt-4">
      {/* Helmet for setting meta description */}
      <Helmet>
        <title>{news.title} - News Details</title>
        <meta name="description" content={news.metaDescription || "Lead4earth News"} />
      </Helmet>
      
      <h1>{news.title}</h1>
      <div className="d-flex justify-content-between">
        <p><strong>By:</strong> {news.author}</p>
        <p><strong>Date:</strong> {new Date(news.date).toLocaleDateString()}</p>
      </div>
      {news.image && <img src={news.image} alt="News" className="img-fluid" />}
      <div className="mt-3" dangerouslySetInnerHTML={{ __html: news.content }}></div>
    </div>
  );
};

export default NewsDetail;
