import React, { useEffect, useState } from "react";
import axios from "axios";

const Joinwithuslist = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URLVAR}/api/joinwithus`)
      .then((response) => setUsers(response.data))
      .catch((err) => console.error(err));
  }, []);

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">User Submissions</h1>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Age</th>
            <th>Address</th>
            <th>Postal Code</th>
            <th>Area of Interest</th>
            <th>Other Interests</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user._id}>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.age}</td>
              <td>{user.address}</td>
              <td>{user.postalCode}</td>
              <td>{user.areaOfInterest.join(", ")}</td>
              <td>{user.otherInterest}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Joinwithuslist;
