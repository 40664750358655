import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const WorkshopDetails = () => {
  const { id } = useParams();
  const [workshop, setWorkshop] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  });

  useEffect(() => {
    const fetchWorkshop = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_URLVAR}/api/workshops/${id}`);
        setWorkshop(response.data.workshop);
      } catch (error) {
        console.error("Error fetching workshop details:", error);
      }
    };
    fetchWorkshop();
  }, [id]);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRSVPSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${process.env.REACT_APP_URLVAR}/api/workshops/rsvp`, { workshopId: id, ...formData });
      alert("RSVP submitted successfully!");
    } catch (error) {
      console.error("Error submitting RSVP:", error);
      alert("Error submitting RSVP.");
    }
  };

  return (
    <div className="container mt-5">
      {workshop && (
        <>
          <h2>{workshop.name}</h2>
          <img src={workshop.banner} alt={workshop.name} className="img-fluid mb-3" />
          <p>{workshop.details}</p>
          <form onSubmit={handleRSVPSubmit} className="mt-4">
            <div className="mb-3">
              <label>Name:</label>
              <input type="text" name="name" value={formData.name} onChange={handleInputChange} className="form-control" required />
            </div>
            <div className="mb-3">
              <label>Email:</label>
              <input type="email" name="email" value={formData.email} onChange={handleInputChange} className="form-control" required />
            </div>
            <div className="mb-3">
              <label>Phone Number:</label>
              <input type="tel" name="phoneNumber" value={formData.phoneNumber} onChange={handleInputChange} className="form-control" required />
            </div>
            <button type="submit" className="btn btn-primary">RSVP</button>
          </form>
        </>
      )}
    </div>
  );
};

export default WorkshopDetails;
