import React, { useState, useEffect } from "react";
import axios from "axios";

const NewsForm = () => {
  const [formData, setFormData] = useState({
    title: "",
    author: "",
    date: "",
    content: "",
    slug: "",
    metaDescription: "",
    image: null,
  });
  const [newsList, setNewsList] = useState([]); // List of news items
  const [isEditing, setIsEditing] = useState(false); // Editing mode
  const [newsId, setNewsId] = useState(null); // ID of the news being edited

  // Fetch news list
  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_URLVAR}/api/news`);
        setNewsList(response.data);
      } catch (error) {
        console.error("Error fetching news list", error);
      }
    };
    fetchNews();
  }, []);

  // Handle input change
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: files ? files[0] : value,
    }));
  };

  // Handle submit (add or edit news)
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    Object.keys(formData).forEach((key) => data.append(key, formData[key]));

    try {
      if (isEditing) {
        await axios.put(`${process.env.REACT_APP_URLVAR}/api/news/${newsId}`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        alert("News updated successfully!");
        setIsEditing(false);
        setNewsId(null);
      } else {
        await axios.post(`${process.env.REACT_APP_URLVAR}/api/news`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        alert("News added successfully!");
      }
      // Reset form and refresh news list
      setFormData({
        title: "",
        author: "",
        date: "",
        content: "",
        slug: "",
        metaDescription: "",
        image: null,
      });
      const response = await axios.get(`${process.env.REACT_APP_URLVAR}/api/news`);
      setNewsList(response.data);
    } catch (error) {
      console.error("Error submitting news", error);
    }
  };

  // Handle edit
  const handleEdit = (newsItem) => {
    setIsEditing(true);
    setNewsId(newsItem._id);
    setFormData({
      title: newsItem.title,
      author: newsItem.author,
      date: newsItem.date.slice(0, 10), // Format date for input
      content: newsItem.content,
      slug: newsItem.slug,
      metaDescription: newsItem.metaDescription,
      image: null,
    });
  };

  // Handle cancel editing
  const handleCancelEdit = () => {
    setIsEditing(false);
    setNewsId(null);
    setFormData({
      title: "",
      author: "",
      date: "",
      content: "",
      slug: "",
      metaDescription: "",
      image: null,
    });
  };

  return (
    <div className="container">
      <form className="p-4 border rounded col-md-10 mx-auto shadow-lg" onSubmit={handleSubmit}>
        <h2>{isEditing ? "Edit News" : "Add News"}</h2>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">Title</label>
          <input type="text" name="title" className="form-control" value={formData.title} onChange={handleChange} required />
        </div>
        <div className="mb-3">
          <label htmlFor="author" className="form-label">Author</label>
          <input type="text" name="author" className="form-control" value={formData.author} onChange={handleChange} required />
        </div>
        <div className="mb-3">
          <label htmlFor="date" className="form-label">Date</label>
          <input type="date" name="date" className="form-control" value={formData.date} onChange={handleChange} required />
        </div>
        <div className="mb-3">
          <label htmlFor="slug" className="form-label">Slug</label>
          <input type="text" name="slug" className="form-control" value={formData.slug} onChange={handleChange} required />
          <small className="form-text text-muted">Enter a unique URL-friendly slug for this news item (e.g., "climate-action-2024").</small>
        </div>
        <div className="mb-3">
          <label htmlFor="metaDescription" className="form-label">Meta Description</label>
          <textarea name="metaDescription" className="form-control" rows="3" value={formData.metaDescription} onChange={handleChange} required></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="content" className="form-label">Content</label>
          <textarea name="content" className="form-control" rows="5" value={formData.content} onChange={handleChange} required></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="image" className="form-label">Image</label>
          <input type="file" name="image" className="form-control" onChange={handleChange} />
        </div>
        <div className="d-flex justify-content-between">
          <button type="submit" className="btn btn-custom-primary">{isEditing ? "Update" : "Submit"}</button>
          {isEditing && (
            <button type="button" className="btn btn-secondary" onClick={handleCancelEdit}>Cancel</button>
          )}
        </div>
      </form>

      <div className="mt-5">
        <h2>News List</h2>
        {newsList.length > 0 ? (
          <ul className="list-group">
            {newsList.map((newsItem) => (
              <li key={newsItem._id} className="list-group-item d-flex justify-content-between align-items-center">
                <span>{newsItem.title}</span>
                <button className="btn btn-primary btn-sm" onClick={() => handleEdit(newsItem)}>Edit</button>
              </li>
            ))}
          </ul>
        ) : (
          <p>No news available.</p>
        )}
      </div>
    </div>
  );
};

export default NewsForm;
