import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet"; // Import Helmet for dynamic meta tags

const InitiativeDetails = () => {
  const { state: initiative } = useLocation();
  const navigate = useNavigate();

  if (!initiative) {
    return (
      <div className="text-center">
        <h1>Initiative not found</h1>
        <button className="btn btn-primary mt-3" onClick={() => navigate("/")}>
          Back to Initiatives
        </button>
      </div>
    );
  }

  return (
    <div>
      {/* Helmet for Dynamic Meta Description */}
      <Helmet>
        <title>{initiative.title}</title>
        <meta
          name="description"
          content={initiative.metaDescription || "Initiative of leadforearth"}
        />
      </Helmet>

      {/* Dynamic Header */}
      <header className="initiative-header text-white text-center">
        <img className="img-fluid" src={initiative.image} alt={initiative.title} />
      </header>

      {/* Render Custom Content */}
      <div className="container my-5">
        <div dangerouslySetInnerHTML={{ __html: initiative.customContent }} />
      </div>
    </div>
  );
};

export default InitiativeDetails;
