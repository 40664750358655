import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { CKEditor5Editor } from "../CKEditor/CKEditor5Editor";
import LocationInputForm from "../LocationInput/LocationInputForm";
import { jwtDecode } from "jwt-decode";

const LeaderUpdate = () => {
  const { id } = useParams();
  const [leader, setLeader] = useState(null); // Changed initial state to null
  const previousContentRef = useRef("");
  const [images, setImages] = useState([]);
  const [errors, setErrors] = useState({});
  const [password, setPassword] = useState("");
  const maxWords = 1000;
  const [mainImageIndex, setMainImageIndex] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // Decode the token to check if the user is an admin
    const token = localStorage.getItem("Token");
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.admin) {
        setIsAdmin(true); // Set to true if the user is an admin
      }
    }
  }, []);
  useEffect(() => {
    const fetchLeader = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URLVAR}/api/leaders/${id}`
        );
        // console.log(response.data);
        setLeader(response.data);
        setImages(response.data.images || []);
        setMainImageIndex(
          response.data.images
            ? response.data.images.findIndex(
                (image) => image === response.data.image
              )
            : null
        );
      } catch (error) {
        console.error("Error fetching leader:", error);
      }
    };
    fetchLeader();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "radius") {
      const radiusValue = parseInt(value, 10);
      if (radiusValue < 0 || radiusValue > 250) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          radius: "Radius should be between 0 and 250 miles",
        }));
        return;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          radius: null,
        }));
      }
    }
    setLeader((prevLeader) => ({ ...prevLeader, [name]: value }));
  };

  const handleCKEditorChange = (e, editor) => {
    const newValue = editor.getData();
    const wordCount = countWords(newValue);

    if (wordCount <= maxWords) {
      setLeader({ ...leader, aboutThem: newValue });
      previousContentRef.current = newValue;
    } else {
      alert(`Word limit exceeded! Maximum allowed words are ${maxWords}.`);
      editor.setData(previousContentRef.current);
    }
  };

  const countWords = (text) => {
    return text.split(/\s+/).filter((word) => word.length > 0).length;
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...files]);
  };
  const handleMainImageChange = (index) => {
    setMainImageIndex(index);
  };
  const handleLocationSelect = (location) => {
    setLeader((prevLeader) => ({
      ...prevLeader,
      location: {
        location: `${location.city}, ${location.state}, ${location.country}`,
        lat: `${location.latitude}`,
        lng: `${location.longitude}`,
      },
    }));
  };

  const handleSocialChange = (e, index) => {
    const { name, value } = e.target;
    const updatedSocials = [...leader.socials];
    updatedSocials[index] = { ...updatedSocials[index], [name]: value };
    setLeader({ ...leader, socials: updatedSocials });
  };

  const deleteSocial = (index) => {
    const updatedSocials = [...leader.socials];
    updatedSocials.splice(index, 1);
    setLeader({ ...leader, socials: updatedSocials });
  };

  const addSocial = () => {
    setLeader({
      ...leader,
      socials: [...leader.socials, { platform: "", link: "" }],
    });
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);
  };

  const handleEndorsementChange = (e, index) => {
    const { name, value, files } = e.target;
    const updatedEndorsements = [...leader.endorsements];
    if (files) {
      updatedEndorsements[index] = {
        ...updatedEndorsements[index],
        [name]: files[0],
      };
    } else {
      updatedEndorsements[index] = {
        ...updatedEndorsements[index],
        [name]: value,
      };
    }
    setLeader({ ...leader, endorsements: updatedEndorsements });
  };

  const addEndorsement = () => {
    setLeader({
      ...leader,
      endorsements: [...leader.endorsements, { name: "", image: null }],
    });
  };

  const handlePriorityChange = (e, index) => {
    const { name, value, files } = e.target;
    const updatedPriorities = [...leader.priorities];
    if (files) {
      updatedPriorities[index] = {
        ...updatedPriorities[index],
        [name]: files[0],
      };
    } else {
      updatedPriorities[index] = { ...updatedPriorities[index], [name]: value };
    }
    setLeader({ ...leader, priorities: updatedPriorities });
  };

  const addPriority = () => {
    setLeader((prevLeader) => ({
      ...prevLeader,
      priorities: [...prevLeader.priorities, { text: "", desc: "", img: null }],
    }));
  };

  const deletePriority = (index) => {
    setLeader((prevLeader) => {
      const updatedPriorities = [...prevLeader.priorities];
      updatedPriorities.splice(index, 1);
      return { ...prevLeader, priorities: updatedPriorities };
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateMobile = (mobile) => {
    const mobileRegex = /^(?:\+1\()?(\d{3})\)?(\d{3})-?(\d{4})$/;
    return mobileRegex.test(mobile);
  };
  const handleDeleteImage = async (index) => {
    const imageToDelete = images[index];
  
    try {
      // Make API call to delete the image from the backend
      await axios.delete(
        `${process.env.REACT_APP_URLVAR}/api/leaders/${leader._id}/images/${encodeURIComponent(imageToDelete)}`,
        {
          headers: {
            'x-auth-token': localStorage.getItem('Token'),
          },
        }
      );
  
      // Update the images state after deletion
      const updatedImages = images.filter((_, i) => i !== index);
      setImages(updatedImages);
  
      // Reset the main image index if necessary
      if (index === mainImageIndex) {
        setMainImageIndex(updatedImages.length > 0 ? 0 : null);
      }
  
      alert('Image deleted successfully!');
    } catch (error) {
      console.error('Error deleting image:', error);
      alert('Failed to delete image');
    }
  };
  
  const validateForm = () => {
    let formErrors = {};

    if (!leader.email || !validateEmail(leader.email)) {
      formErrors.email = "Please enter a valid email address";
    }

    if (!leader.mobile || !validateMobile(leader.mobile)) {
      formErrors.mobile = "Please enter a valid mobile number (10-15 digits)";
    }

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    // console.log(leader);
    const formData = new FormData();
    formData.append("name", leader.name);
    formData.append("location", leader.location.location);
    formData.append("lat", leader.location.lat);
    formData.append("lng", leader.location.lng);
    formData.append("email", leader.email);
    formData.append("mobile", leader.mobile);
    formData.append("aboutThem", leader.aboutThem);
    formData.append("shortDescription", leader.shortDescription);
    // formData.append("image", leader.image);
    formData.append("preferred", leader.preferred);
    formData.append("youtubeVideo", leader.youtubeVideo);
    formData.append("donationLink", leader.donationLink);
    formData.append("tagline", leader.tagline);
    formData.append("website", leader.website);
    formData.append("title", leader.title);
    formData.append("mainImageIndex", mainImageIndex);
    if (leader.radius) {
      formData.append("radius", leader.radius);
    }

    if (leader.mapImage) {
      formData.append("mapImage", leader.mapImage);
    }
    if (password !== "") {
      formData.append("password", password);
    }
    images.forEach((image, index) => {
      if (typeof image === "object") {
        // Only append new files, not URLs
        formData.append("images", image);
      }
    });
    formData.append("socials", JSON.stringify(leader.socials));

    leader.endorsements.forEach((endorsement, index) => {
      formData.append(`endorsements[${index}][name]`, endorsement.name);
      if (endorsement.image) {
        formData.append(`endorsements[${index}][image]`, endorsement.image);
      }
    });

    leader.priorities.forEach((priority, index) => {
      formData.append(`priorities[${index}][text]`, priority.text);
      if (priority.img) {
        formData.append(`priorities[${index}][image]`, priority.img);
      }
      formData.append(`priorities[${index}][desc]`, priority.desc);
    });
    console.log(leader.priorities);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_URLVAR}/api/leaders/${leader._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-auth-token": localStorage.getItem("Token"),
          },
        }
      );
      console.log(response);
      alert("Leader updated successfully!");
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to update leader");
    }
  };

  if (!leader) return <div>Loading...</div>;

  return (
    <div className="container">
      <h2 className="mt-5">Update Leader</h2>
      <form
        className="border border-1 rounded p-3 mb-2"
        onSubmit={handleSubmit}
        encType="multipart/form-data"
      >
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Name
          </label>
          <input
            type="text"
            className={`form-control ${errors.name ? "is-invalid" : ""}`}
            id="name"
            name="name"
            value={leader.name}
            onChange={handleChange}
          />
          {errors.name && <div className="invalid-feedback">{errors.name}</div>}
        </div>

        {/* <div className="mb-3">
          <label htmlFor="location" className="form-label">
            Location
          </label>
          <input
            type="text"
            className="form-control"
            id="location"
            name="location"
            value={leader.location}
            onChange={handleChange}
          />
        </div> */}
        <div className="mb-3">
          <label htmlFor="location">Location</label>
          <LocationInputForm
            onLocationSelect={handleLocationSelect}
            enteredvalue={leader.location}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className={`form-control ${errors.email ? "is-invalid" : ""}`}
            id="email"
            name="email"
            value={leader.email}
            onChange={handleChange}
          />
          {errors.email && (
            <div className="invalid-feedback">{errors.email}</div>
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            type="text"
            className={`form-control `}
            id="password"
            name="password"
            value={password}
            onChange={handlePasswordChange}
          />
        </div>
 
        <div className="mb-3">
          <label htmlFor="mobile" className="form-label">
            Mobile
          </label>
          <input
            type="text"
            className={`form-control ${errors.mobile ? "is-invalid" : ""}`}
            id="mobile"
            name="mobile"
            value={leader.mobile}
            onChange={handleChange}
          />
          {errors.mobile && (
            <div className="invalid-feedback">{errors.mobile}</div>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="aboutThem" className="form-label">
            About Them
          </label>
          <CKEditor5Editor
            value={leader.aboutThem}
            onChange={handleCKEditorChange}
          />
        </div>
        {isAdmin && (
                <div className="mb-3 col-md-6 pt-4">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="preferred"
                    name="preferred"
                    checked={leader.preferred}
                    onChange={(e) =>
                      setLeader({ ...leader, preferred: e.target.checked })
                    }
                  />
                  <label htmlFor="preferred" className="form-label ms-2">
                    Preferred Leader (It will shown on Banner)
                  </label>
                </div>
              )}
        <div className="mb-3">
          <label htmlFor="shortDescription" className="form-label">
            Short Description
          </label>
          <input
            type="text"
            className="form-control"
            id="shortDescription"
            name="shortDescription"
            value={leader.shortDescription}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="image" className="form-label">
            Images
          </label>
          <input
            type="file"
            className="form-control"
            id="image"
            name="image"
            multiple
            onChange={handleFileChange}
          />
          <a href={leader.image} target="_blank">
            Main Image
          </a>
          <div className="mt-3 row">
            {images.map((image, index) => (
              <div
                key={index}
                className="d-flex flex-column align-items-center mb-2 col-md-3"
              >
                <label for={`slectimg${index}`}>
                  <img
                    src={
                      typeof image === "string"
                        ? image
                        : URL.createObjectURL(image)
                    }
                    alt={`Image ${index + 1}`}
                    className="img-thumbnail me-2"
                    style={{
                      width: "100px",
                      aspectRatio: "1/1",
                      objectFit: "contain",
                    }}
                  />
                </label>
                <div>
                  <input
                    id={`slectimg${index}`}
                    type="radio"
                    name="mainImage"
                    checked={index === mainImageIndex}
                    onChange={() => handleMainImageChange(index)}
                  />
                  <label for={`slectimg${index}`} className="ms-2">
                    Set as Main Image
                  </label>
                </div>
                <button
                  type="button"
                  className="btn btn-danger mt-2"
                  onClick={() => handleDeleteImage(index)}
                >
                  Delete Image
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="youtubeVideo" className="form-label">
            YouTube Video
          </label>
          <input
            type="text"
            className="form-control"
            id="youtubeVideo"
            name="youtubeVideo"
            value={leader.youtubeVideo}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="donationLink" className="form-label">
            Donation Link
          </label>
          <input
            type="text"
            className="form-control"
            id="donationLink"
            name="donationLink"
            value={leader.donationLink}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="tagline" className="form-label">
            Tagline
          </label>
          <input
            type="text"
            className="form-control"
            id="tagline"
            name="tagline"
            value={leader.tagline}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="website" className="form-label">
            Website
          </label>
          <input
            type="text"
            className="form-control"
            id="website"
            name="website"
            value={leader.website}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Title
          </label>
          <input
            type="text"
            className="form-control"
            id="title"
            name="title"
            value={leader.title}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="mapImage" className="form-label">
            Map Image (Add map image of influence if you have any)
          </label>
          <input
            type="file"
            className="form-control"
            id="mapImage"
            name="mapImage"
            onChange={handleFileChange}
          />
          {leader.mapImage && (
            <a
              className="text-decoration-underline text-primary mt-3"
              target="_blank"
              href={leader.mapImage}
            >
              Map Image
            </a>
          )}
        </div>
        {!leader.mapImage && (
          <div className="mb-3">
            <label htmlFor="radius" className="form-label">
              Approx radius of area of influence from Your location (0 -250
              miles)
            </label>
            <input
              type="number"
              className="form-control"
              id="radius"
              name="radius"
              value={leader.radius}
              onChange={handleChange}
            />
          </div>
        )}

        <div className="mb-3">
          <label className="form-label">Socials</label>
          {leader.socials.map((social, index) => (
            <div key={index} className="d-flex mb-2">
              <select
                className="form-select me-2"
                name="platform"
                value={social.platform}
                onChange={(e) => handleSocialChange(e, index)}
              >
                <option value="">Select Platform</option>
                <option value="linkedin">LinkedIn</option>
                <option value="instagram">Instagram</option>
                <option value="facebook">Facebook</option>
                <option value="twitter">Twitter</option>
                <option value="github">GitHub</option>
                <option value="other">Other</option>
              </select>
              <input
                type="text"
                className="form-control me-2"
                placeholder="Link"
                name="link"
                value={social.link}
                onChange={(e) => handleSocialChange(e, index)}
              />
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => deleteSocial(index)}
              >
                Delete
              </button>
            </div>
          ))}
          <button
            type="button"
            className="btn btn-secondary"
            onClick={addSocial}
          >
            Add Social
          </button>
        </div>

        <div className="mb-3">
          <label className="form-label">Priorities : </label>
          {leader.priorities.map((priority, index) => (
            <div key={index} className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Priority Text"
                name="text"
                value={priority.text}
                onChange={(e) => handlePriorityChange(e, index)}
              />
              <textarea
                type="textarea"
                className="form-control"
                placeholder="Priority Description"
                name="desc"
                value={priority.desc}
                onChange={(e) => handlePriorityChange(e, index)}
              />
              <input
                type="file"
                className="form-control"
                name="img"
                onChange={(e) => handlePriorityChange(e, index)}
              />
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={() => deletePriority(index)}
              >
                Delete
              </button>
            </div>
          ))}
          <br />
          <button
            type="button"
            className="btn btn-secondary"
            onClick={addPriority}
          >
            Add Priority
          </button>
        </div>

        <button type="submit" className="btn btn-success">
          Update Leader
        </button>
      </form>
    </div>
  );
};

export default LeaderUpdate;
