// components/NewsList.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const NewsList = () => {
  const [news, setNews] = useState([]);
  const [visible, setVisible] = useState(3);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchNews = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URLVAR}/api/news`
      );
      setNews(data);
    };
    fetchNews();
  }, []);

  const showMore = () => setVisible((prev) => prev + 3);

  const readMore = (slug) => {
    navigate(`/news/${slug}`);
  };

  return (
    <div className="container mt-4">
      {news.length>0 && (
        <>
          <h2 className="about-heading my-5 text-center">News</h2>
          <div className="row">
            {Array.isArray(news) &&
              news.slice(0, visible).map((item, index) => (
                <div className="col-md-4 mb-4" key={index}>
                  <div className="card h-100 shadow-lg">
                    {item.image && (
                      <img
                        src={item.image}
                        className="card-img-top"
                        alt="News"
                      />
                    )}
                    <div className="card-body">
                      <h5 className="card-title">
                        {item.title.slice(0, 50)}...
                      </h5>
                      {/* <p className="card-text">{item.content.slice(0, 100)}...</p> */}
                      <div className=" d-flex justify-content-between text-muted">
                        <span>{item.author}</span>
                        <span>{new Date(item.date).toLocaleDateString()}</span>
                      </div>
                      <div className=" d-flex justify-content-end mt-2">
                        <button
                          className="btn btn-custom-primary"
                          onClick={() => readMore(item.slug)}
                        >
                          Read More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {visible < news.length && (
            <button className="btn btn-secondary mt-3" onClick={showMore}>
              Show More
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default NewsList;
